.about-content-wrapper {
    @include main-content;
    padding-top: 190px;
    padding-bottom: 130px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 2px solid $royalblue;




    h4 {
        font-size: 2.75rem;
    }

    p {
        font-size: 2rem;

    }
}