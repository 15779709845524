.create {
    @include main-content;
    padding-top: 230px;
    padding-bottom: 130px;
    border-bottom: 2px solid $royalblue;

    h2 {
        font-size: 3rem;
        padding-left: 130px;
    }

    p {
        font-size: 2rem;
        padding-left: 110px;
        padding-right: 100px;
        font-weight: 500;
    }

.donation-form-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

    label {
        padding-right: 10px;
        font-weight: 500;
    }

    .input-wrapper {
        padding-bottom: 20px;
        font-size: 2rem;

        ::placeholder {
            text-align: center;
            height: 30px;
            font-size: 1.75rem;
        }

        .select-input {
            height: 40px;
            width: 290px;
        }

        .select-element {
            height: 40px;
            text-align: center;
            font-size: 2rem;
           
        }

    }

        button {
            height: 30px;
            width: 80px;
            font-size: 2rem;
            color: white;
            background-color: $mediumblue;
        }
}
}