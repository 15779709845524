
.header-wrapper {
 position: fixed;
 width: 100%;
 height: 170px;
 background-color: white;
 border-bottom: 2px solid $royalblue;



.nav-wrapper {
    display: flex;
    justify-content: left;
    padding: 30px;
 }
}

.nav-link-wrapper {
  height: 22px;
  border-bottom: 1px solid transparent;  
  transition: 0.5s ease-in-out;

  margin-right: 20px;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight:600;

  @media screen and(max-width: 600px) {
    font-size: 1.10rem;
  }

  .nav-link-active {
    border-bottom: 1px solid $royalblue;
    color: $royalblue;
  }

  a {
    color: $mediumblue;
    text-decoration: none;
    &:hover {
        color: $royalblue;
    }
  }

  &:hover {
    border-bottom: 1px solid $royalblue;
  }
}