html {
    font-size: 62.5%; // 1rem is 10px
    font-family: 'montserrat';
    height: 100%;

    }

body {
    font-weight: normal;
    margin: 0px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

