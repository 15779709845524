


.contact-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    padding-top: 320px;
    padding-bottom: 180px;
    border-top: 2px solid $royalblue;
    border-bottom: 2px solid $royalblue;
    background-color: $lemon;

    h3 {
        display: flex;
        color: $mediumblue;
        justify-content: center;
        font-size: 3rem;

    }

    h2 {
        display: flex;
        justify-content: center; 
        font-size: 2rem;
    }
}