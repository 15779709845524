.app-wrapper {
    display: grid;


    .app {
        display: grid;
    }
     
    h1{
    
    
        display: grid;
        padding-left: 80px;
        font-size: 3rem;
}
}