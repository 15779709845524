$lemon: #FFFACD;
$gold: #FFD700;
$mediumblue: #0000CD;
$royalblue: #4169E1;

@mixin main-content{
    display: grid;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    border-top: 2px solid $royalblue;
    background-color: $lemon;
}

@mixin starter {
    display: grid;
    justify-items: center;
}