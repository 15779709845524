.app-wrapper {
  display: grid;
}

.app-wrapper .app {
  display: grid;
}

.app-wrapper h1 {
  display: grid;
  padding-left: 80px;
  font-size: 3rem;
}

.header-wrapper {
  position: fixed;
  width: 100%;
  height: 170px;
  background-color: white;
  border-bottom: 2px solid #4169E1;
}

.header-wrapper .nav-wrapper {
  display: flex;
  justify-content: left;
  padding: 30px;
}

.nav-link-wrapper {
  height: 22px;
  border-bottom: 1px solid transparent;
  transition: 0.5s ease-in-out;
  margin-right: 20px;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .nav-link-wrapper {
    font-size: 1.10rem;
  }
}

.nav-link-wrapper .nav-link-active {
  border-bottom: 1px solid #4169E1;
  color: #4169E1;
}

.nav-link-wrapper a {
  color: #0000CD;
  text-decoration: none;
}

.nav-link-wrapper a:hover {
  color: #4169E1;
}

.nav-link-wrapper:hover {
  border-bottom: 1px solid #4169E1;
}

.contact-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  padding-top: 320px;
  padding-bottom: 180px;
  border-top: 2px solid #4169E1;
  border-bottom: 2px solid #4169E1;
  background-color: #FFFACD;
}

.contact-wrapper h3 {
  display: flex;
  color: #0000CD;
  justify-content: center;
  font-size: 3rem;
}

.contact-wrapper h2 {
  display: flex;
  justify-content: center;
  font-size: 2rem;
}

html {
  font-size: 62.5%;
  font-family: 'montserrat';
  height: 100%;
}

body {
  font-weight: normal;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

.home-content-wrapper h1 {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  border-top: 2px solid #4169E1;
  background-color: #FFFACD;
  padding-top: 190px;
  padding-bottom: 50px;
  display: grid;
  justify-items: center;
  font-size: 2.10rem;
  padding-left: 10px;
  margin-bottom: 0px;
  border-bottom: 2px solid black;
}

@media screen and (max-width: 600px) {
  .home-content-wrapper h1 {
    font-size: 1.80rem;
    padding-left: 30px;
    padding-right: 20px;
  }
}

.home-content-wrapper .home-quote-wrapper {
  padding-top: 120px;
  padding-bottom: 120px;
  font-size: 2.5rem;
  border-bottom: 3px solid black;
  background-color: #4169E1;
  color: white;
}

@media screen and (max-width: 600px) {
  .home-content-wrapper .home-quote-wrapper {
    font-size: 1.75rem;
  }
}

.home-content-wrapper .home-quote-wrapper h2 {
  display: grid;
  justify-items: center;
}

.home-content-wrapper .home-contact-wrapper h3 {
  display: grid;
  justify-items: center;
  font-size: 1.75rem;
}

.about-content-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  border-top: 2px solid #4169E1;
  background-color: #FFFACD;
  padding-top: 190px;
  padding-bottom: 130px;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 2px solid #4169E1;
}

.about-content-wrapper h4 {
  font-size: 2.75rem;
}

.about-content-wrapper p {
  font-size: 2rem;
}

.quotes-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  border-top: 2px solid #4169E1;
  background-color: #FFFACD;
  padding-right: 30px;
  padding-top: 140px;
  background-color: #4169E1;
}

.quotes-wrapper .quote-content-wrapper {
  display: grid;
  font-size: 1.75rem;
  color: black;
  padding: 60px;
}

.quotes-wrapper .quote-content-wrapper h3 {
  display: grid;
  background-color: #FFFACD;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.create {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  border-top: 2px solid #4169E1;
  background-color: #FFFACD;
  padding-top: 230px;
  padding-bottom: 130px;
  border-bottom: 2px solid #4169E1;
}

.create h2 {
  font-size: 3rem;
  padding-left: 130px;
}

.create p {
  font-size: 2rem;
  padding-left: 110px;
  padding-right: 100px;
  font-weight: 500;
}

.create .donation-form-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.create .donation-form-wrapper label {
  padding-right: 10px;
  font-weight: 500;
}

.create .donation-form-wrapper .input-wrapper {
  padding-bottom: 20px;
  font-size: 2rem;
}

.create .donation-form-wrapper .input-wrapper ::placeholder {
  text-align: center;
  height: 30px;
  font-size: 1.75rem;
}

.create .donation-form-wrapper .input-wrapper .select-input {
  height: 40px;
  width: 290px;
}

.create .donation-form-wrapper .input-wrapper .select-element {
  height: 40px;
  text-align: center;
  font-size: 2rem;
}

.create .donation-form-wrapper button {
  height: 30px;
  width: 80px;
  font-size: 2rem;
  color: white;
  background-color: #0000CD;
}
